import React from "react";
import Container from "@mui/material/Container";
import { Provider } from "react-redux";
import store from "../coms/data/store";

import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";

const HomePage = () => {
  return (
    <>
      <Provider store={store}>
        <MainFrame>
          <Layout>
            <Container
              maxWidth="lg"
              sx={{
                backgroundColor: "#FAFAFA",
                padding: "10px 0px",
              }}
            ></Container>
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default HomePage;
